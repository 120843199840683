.App {
  text-align: center;
  background-color: #282c34;
  color: white;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
  vertical-align: middle;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  font-size: 17px;
  min-height: 60vh;
}

.App-footer {
  font-size: 11px;
  padding: 14px 16px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: #282c34;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

.avatar{
  float: right;
  position: relative;
  display:flex; 
}

.avatar p 
{
  padding:10px;

}

.avatar a {
  float:none;
  padding: 0px;
  color: #f2f2f2;
  text-decoration: underline;
}

.avatar a:hover {
  color: black;
  text-decoration: underline;
}

.avatar a.active {
  background-color: #04AA6D;
  color: white;
  text-decoration: underline;
}

